




import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class SignupRedirect extends Vue {
  @Watch('$auth.loading', { immediate: true })
  redirectToAuth0SignUp() {
    if (this.$auth.loading) {
      return;
    }

    if (this.$auth.isAuthenticated) {
      this.$router.replace('/');
      return;
    }

    this.$auth.loginWithRedirect({
      initialDisplay: 'signup',
      opweb: 'true'
    });
  }
}
